export default {
  cake: {
    56: '0x9801b78CF82A0d72261285642E9Fc75c30Ba0326',
    97: '0xB5A985295cc109DE7e836cB6850D49aBC7781f8f',
  },
  tat: {
    56: '0x55d398326f99059fF775485246999027B3197955', // usdt
    97: '0xDFCd0b91AE2BB34a90D9b4970Dc113DFaf25004d', // Cool
  },
  masterChef: {
    56: '0xfBb9144Ad7C78f7334dBAB76274e82866bd60170',
    97: '0x611FE1d0e4a14825A204976895c5C9C75381a906',
  },
  lockedStacking: {
     56: '0x0f77E9Ccd520aA91499804e2917b2a9C3DB500db', // main net   0xdb6c362244c7eF6F081c6380d461c1f18C8aDE1C
    //  97: "0xAcd0F65ab5530240dC7c6B227D2c61252CD04Dd6" // testnet
     97:"0x67D09507e6649b6F239a518D9aA29069E3DE29Ec" // local
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  },
  lottery: {
    56: '',
    97: '',
  },
  lotteryNFT: {
    56: '',
    97: '',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '0xbCf448aa88d80C1B7bb8Fb5A1e68cd4B6c11c354',
  },
}
