import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    risk: 5,
    lpSymbol: 'MASP-BUSD LP',
    lpAddresses: {
      97: '0xe8Bd519E5F3666a41555dDFDBbc07D243560f5A0',
      56: '0xAe9B76429B80C3fbBea07d8F9182E827f1259FB6',
    },
    tokenSymbol: 'MASP',
    tokenAddresses: {
      97: '0xB5A985295cc109DE7e836cB6850D49aBC7781f8f',
      56: '0x9801b78CF82A0d72261285642E9Fc75c30Ba0326',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 1,
    risk: 5,
    lpSymbol: 'MASP-BNB LP',
    lpAddresses: {
      97: '0xD085E1e2062Ab6661BB4AC5a1775C4620F9d639e',
      56: '0xD83764b962b2B9d2D8F298cBd953661CdE7c1b8E',
    },
    tokenSymbol: 'MASP',
    tokenAddresses: {
      97: '0xB5A985295cc109DE7e836cB6850D49aBC7781f8f',
      56: '0x9801b78CF82A0d72261285642E9Fc75c30Ba0326',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 3,
    risk: 5,
    lpSymbol: 'BNB-BUSD LP',
    lpAddresses: {
      97: '0xD085E1e2062Ab6661BB4AC5a1775C4620F9d639e',
      56: '0xa53094c0a297d92F79D6953638aB6D47BbBe4357',
    },
    tokenSymbol: 'BNB',
    tokenAddresses: {
      97: '0xB5A985295cc109DE7e836cB6850D49aBC7781f8f',
      56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 2,
    risk: 4,
    isTokenOnly: true,
    lpSymbol: 'MASP',
    lpAddresses: {
      97: '0xe8Bd519E5F3666a41555dDFDBbc07D243560f5A0',
      56: '0xAe9B76429B80C3fbBea07d8F9182E827f1259FB6', // CAKE-BUSD LP
    },
    tokenSymbol: 'MASP',
    tokenAddresses: {
      97: '0xB5A985295cc109DE7e836cB6850D49aBC7781f8f',
      56: '0x9801b78CF82A0d72261285642E9Fc75c30Ba0326',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  
]

export default farms
